import React from "react";
import {graphql, Link} from "gatsby";
import "./blogTemplate.scss";
import {Header} from "../../header";
import {Footer} from "../../landingpage/footer";
import {MetaTags} from "../../metaTags";
import {MDXRenderer} from "gatsby-plugin-mdx"

export default function Template({
                                     data
                                 }) {
    const {mdx} = data;
    const {frontmatter, body} = mdx;
    return (
        <div>
            <MetaTags title={frontmatter.title} description={frontmatter.description}/>
            <Header fixedTop={true} backgroundTransition={false}/>
            <section className="ptb-100">
                <div className="main container">
                    <Link to="/blog">
                        Retour à la liste des articles
                    </Link>
                    <div className="row justify-content-center mt-5">
                        <div className="col-md-8 blog-post-container">
                            <h3 dangerouslySetInnerHTML={{__html: frontmatter.title}}/>
                            <h4>{frontmatter.date}</h4>
                            <MDXRenderer className="blog-post-content">{body}</MDXRenderer>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "fr")
        path
        title
        description
      }
    }
  }
`;